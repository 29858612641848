<template>
  <div class="action__inner">
    <a-form :model="dataSource" :rules="rules" @finish="sendTransfer" name="transfer" ref="transfer">
      <p style="margin-bottom: 30px; text-align: justify">
        {{ i18n.t('accounts.trading.reallyClose', 1) }}
        <br />
        {{ i18n.t('accounts.trading.reallyClose', 2) }}
      </p>
      <sdButton
        class="action__button"
        size="large"
        :disabled="isLoading || errorAmount"
        type="danger"
        htmlType="submit"
        raised
      >
        {{ i18n.t('accounts.trading.close') }}
      </sdButton>
      <!--<p class="action__more-info" @click="changePayInfo">
              <sdFeatherIcons type="info" size="20" /> {{ i18n.t('start.moreInfo') }}
            </p>-->
    </a-form>
  </div>
  <!--<div class="action__inner" v-if="visiblePayHint">
          <p class="action__info">
            {{ i18n.t('start.actionTransferHintOne', accId == tokenAccount.id ? 1 : 2) }}
          </p>
          <p class="action__info" v-if="accId == tokenAccount.id">
            {{ i18n.t('start.actionTransferHintTwo') }}
          </p>
        </div>-->
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const TransferModal = {
  name: 'TradingModal',
  props: { error: Boolean, tradingAccount: Object, actionType: String },
  emits: ['dataNotSended', 'dataSended'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { state } = useStore();

    const errorAmount = ref(false);

    const isLoading = computed(() => state.accounts.fileLoading);

    const sendTransfer = () => {
      emit('dataSended');
    };

    return {
      i18n,
      errorAmount,
      isLoading,
      sendTransfer,
    };
  },
};
export default TransferModal;
</script>

<style></style>
